require("@rails/ujs").start()
require("turbolinks").start()
// require("@rails/activestorage").start()
require("jquery")
require("jquery-ui")
require("./lib/jquery.cookie")
require("bootstrap")
require("chartkick")
require("chart.js")
require("packs/lib/sb-admin-2")
require("packs/lib/datetime")
require("packs/lib/3digit_separator")

import "@fortawesome/fontawesome-free/js/all";
import "../src/application_user.scss"
import Vue from 'vue';

require("packs/lib/common")